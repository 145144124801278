<script setup lang="ts">
import { useCountdown } from '~~/src/services/event/composable/useCountdown';

import { useMobile } from '~/composables/useMobile';

import Tracker from '~/libs/Tracker';

import { useCommonStore } from '~/services/common';
import { useCouponStore } from '~/services/coupon/store';
import { useUserProfileStore } from '~/services/profile/store';

import { IconX } from '~/components/5x';

// const router = useRouter();
const route = useRoute();
const userProfileStore = useUserProfileStore();
const { isDesktop } = useMobile();
const couponStore = useCouponStore();
const runtime = useRuntimeConfig();
const timer = ref<any | null>(null);
const commonStore = useCommonStore();

const dueDate = computed(() => {
  // const due = '2024-09-19 23:59:59';

  if (runtime.public.app_env !== 'prod' && route.query.dueDate) {
    return new Date(route.query.dueDate || null);
  }
  const due = new Date();
  due.setHours(23, 59, 59, 999);
  return due;
});

const initTime = async (dueDate: Date) => {
  timer.value = useCountdown(dueDate, 'HH : mm : ss');
};

const clickCloseButton = () => {
  couponStore.setClassWelcomePopup(false);
  Tracker['Click Button']('welcome-coupon-close', '웰컴 쿠폰 닫기');
};

const clickButton = () => {
  couponStore.setClassWelcomePopup(false);
  Tracker['Click Button']('welcome-coupon-popup-button', '최대 할인 받고 구매하기');
  setTimeout(() => {
    commonStore.showToast({
      text: '신규가입 축하쿠폰이 발급되었습니다.',
      duration: 3000,
      align: 'left',
    });
  }, 500);
};

const handleVisible = computed<boolean>({
  get: () => {
    if (route.path === '/signup') {
      return false;
    }

    return couponStore.getClassWelcomePopupVisible;
  },
  set: (val) => {
    couponStore.setClassWelcomePopup(val);
  },
});

watch(
  () => couponStore.getClassWelcomePopupVisible,
  (newVal) => {
    if (newVal) {
      initTime(dueDate.value);
      Tracker['View Popup']({
        popupType: 'welcome-coupon',
      });
    } else {
      timer.value.clear();
      timer.value = null;
    }
  },
);
watch(
  () => timer.value?.isFinish,
  (newVal) => {
    if (newVal) {
      const due = new Date();
      due.setHours(23, 59, 59, 999);
      initTime(due);
    }
  },
);
</script>

<template>
  <Dialog
    v-model:visible="handleVisible"
    header=""
    :breakpoints="{ '1199px': '383px', '960px': '383px', '640px': '100vw' }"
    :modal="true"
    :closable="false"
    :draggable="false"
    :position="isDesktop ? 'center' : 'bottom'"
    :base-z-index="2000"
    :auto-z-index="true"
    class="event-welcome-dialog">
    <template #header>
      <div class="button-container">
        <IconX class="w-7 h-7 text-neutral-500" thickness="extra-light" @click="clickCloseButton" />
      </div>
    </template>
    <div class="event-message-container">
      <div class="header-message">
        <div>
          <b class="text-2xl leading-[30px] text-neutral-1000">신규가입 축하쿠폰</b>
        </div>
        <div>
          <p class="text-neutral-800 text-sm font-normal leading-tight text-center">
            <span class="text-primary-600"
              >{{ userProfileStore.profile?.nickName || userProfileStore.profile?.userName }}님</span
            >에게 쿠폰이 발급되었어요! <br />최대 할인받고 수강하세요.
          </p>
        </div>
      </div>
      <div class="w-[250px] my-4">
        <img :src="$cdn() + `/fe/dialog/event-dialog/pc/welcome_coupon_4x.webp`" class="w-full block" />
      </div>
      <div class="text-neutral-800 text-sm font-normal leading-tight">쿠폰할인 남은 시간</div>
      <div class="text-red-600 text-2xl font-bold leading-[30px] tabular-nums">
        {{ timer?.remaining }}
      </div>
    </div>

    <template #footer>
      <Button class="event-welcome-dialog-button" label="최대 할인 받고 구매하기" text autofocus @click="clickButton" />
    </template>
  </Dialog>
</template>

<style lang="scss">
.event-welcome-dialog {
  width: 383px;
  @media screen and (max-width: $md) {
    width: 100%;
    margin: 0 !important;
  }
  .p-dialog-header {
    padding: 44px 40px;
    display: flex;
    justify-content: center;
    position: relative;
    .button-container {
      position: absolute;
      display: flex;
      align-items: flex-end;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
  }
  .p-dialog-content {
    padding: 0 40px;
    @media screen and (max-width: $md) {
      padding: 0px 27.5px;
    }
  }
  .p-dialog-header {
    padding: 44px 40px 0px;
    @media screen and (max-width: $md) {
      padding: 32px 0 0;
      border-radius: 32px 32px 0px 0px;
    }
  }
  .p-dialog-footer {
    display: flex;
    padding: 0px 20px 28px;
    gap: 8px;
    @media screen and (max-width: $md) {
      padding: 0px 24px 24px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .event-welcome-dialog-button {
      width: 100%;
      border-radius: 4px;
      background: #1f5af2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      color: #fff;
      margin: 0;
      padding: 10px 16px;
      height: 48px;
      &:hover {
        background: #1f5af2;
        color: #fff;
      }
    }
  }
}
.event-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  .header-message {
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin: 24px 0;
    color: #1c2a4b;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
  }
  .header-message div {
    display: flex;
  }
}
</style>
