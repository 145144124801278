const isUrl = (url) => {
  const urlRegex = /^(https?|HTTPS?|HTTPS?):\/\//;
  return urlRegex.test(url);
};

const isLocalHost = (url) => {
  const localhostRegex =
    /^(?:https?:\/\/)?(?:www\.)?(localhost|127(?:\.[0-9]{1,3}){3}|[0-9]{1,3}(?:\.[0-9]{1,3}){3})(?::[0-9]+)?(?:\/.*)?$/;
  return localhostRegex.test(url);
};

const isWeolbuUrl = (url) => {
  //   const regex = /^(?:https?:\/\/)?(?:www\.)?weolbu\.com/gi;
  const regex = /^(?!.*\b(b2b\.weolbu\.com)\b).*weolbu\.com/;
  return regex.test(url);
};

const isTallyUrl = (url: string) => {
  const regex = /^(?:https?:\/\/)?(?:www\.)?tally\.so/;
  return regex.test(url);
};

const isChannelUrl = (url: string) => {
  const regex = /^(?:https?:\/\/)?(?:weolbu\.)?channel\.io/;
  return regex.test(url);
};

const parseUrl = (url) => {
  const urlRegex = /^(?:(?:(https?|ftp):\/\/)(?:([^:/?#\s]+)(?::(\d+))?)?)?([^?#\s]+)?(?:\?([^#\s]*))?(?:#([^\s]*))?/;
  const match = url.match(urlRegex);
  if (!match) {
    return null;
  }

  const [, protocol, hostname, port, pathname, search, hash] = match;
  const parsedUrl = {
    protocol: protocol || 'http',
    hostname: hostname || '',
    port: port || '',
    pathname: pathname || '',
    search: search || '',
    hash: hash || '',
  };
  return parsedUrl;
};

const searchToObject = (search) => {
  const params = new URLSearchParams(search);
  const paramsObj = {};

  for (const [key, value] of params.entries()) {
    paramsObj[key] = value;
  }
  return paramsObj;
};

// isWeolbuUrl 함수를 사용한 다음이라고 가정한다. 월부 닷컴인데 다른 도메인인경우 현재 도메인으로 변경해주는 함수
const replaceWeolbuUrl = (url, isHostName = true) => {
  const parsedUrl = parseUrl(url);
  // console.log('parsedUrl', parsedUrl);

  if (parsedUrl) {
    let resultUrl = '';
    if (isHostName) {
      resultUrl = window.location.origin;
    }

    resultUrl += parsedUrl.pathname;

    if (!$isEmpty(parsedUrl.search)) {
      resultUrl += `?${parsedUrl.search}`;
    }

    if (!$isEmpty(parsedUrl.hash)) {
      resultUrl += `#${parsedUrl.hash}`;
    }

    return resultUrl;
  } else {
    return null;
  }
};

export default {
  isUrl,
  isLocalHost,
  isWeolbuUrl,
  isTallyUrl,
  isChannelUrl,
  parseUrl,
  replaceWeolbuUrl,
  searchToObject,
};
