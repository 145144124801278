<script setup lang="ts">
import type { BeforeInstallPromptEvent } from '../type';

const promptEvent = ref<BeforeInstallPromptEvent | null>(null);

const handleBeforeInstallPrompt = (e: Event) => {
  e.preventDefault();
  promptEvent.value = e as BeforeInstallPromptEvent;
};

onBeforeMount(() => {
  window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
});

provide('promptEvent', promptEvent);
</script>

<template>
  <slot />
</template>
