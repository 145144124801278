<script setup lang="ts">
import { Provider } from '~/policy';

import Broadcast from '~/libs/Broadcast';
import Tracker from '~/libs/Tracker';

import { useBasicsStore } from '~/services/base';
import { CommunityMainTabNumbers } from '~/services/main/constant';
import { useUserAuthStore } from '~/services/userAuth/store';

import { StorageItem } from '~/shared/StorageItem';
import { PROVIDER_STATE_KEY_PREFIX, REDIRECT_URI } from '~/shared/auth/client';

import CaptchaLoginForm from '~/components/login/CaptchaLoginForm.vue';
import LoginForm from '~/components/login/LoginForm.vue';
import LoginFormBottomSection from '~/components/login/LoginFormBottomSection.vue';

let redirectUriItem;
const router = useRouter();
const route = useRoute();
const basicStore = useBasicsStore();
const userAuthStore = useUserAuthStore();

const closeButtonVisible = computed(() => {
  const filterRequiredPages = ['/payment'];
  return !filterRequiredPages.includes(route.path);
});

const duplicatedAccounts = ref({
  createdAt: '',
  phoneNumber: '',
  accounts: [],
});
const accountData = reactive({
  email: '',
  userPwd: '',
});

const captcha = reactive({
  url: '',
  identifyKey: '',
  value: '',
});
const failCount = ref(0);

// 이메일 로그인 처리
const handleSignin = async () => {
  // 입력 검증 함수
  const validateInputs = async () => {
    if (accountData.email === '') {
      await $alert('아이디를 입력해주세요.');
      return false;
    }
    if (accountData.userPwd === '') {
      await $alert('비밀번호를 입력해주세요.');
      return false;
    }
    if (captcha.identifyKey && captcha.value === '') {
      await $alert('자동 입력 방지 문자를 입력해주세요.');
      return false;
    }
    return true;
  };

  // 로그인 후 처리 함수
  const handleLoginSuccess = async (data) => {
    const prevCommunityTab = sessionStorage.getItem('prevCommunityTab') ?? '';
    if ([CommunityMainTabNumbers.FOLLOW, CommunityMainTabNumbers.MY_ACTIVITY].includes(prevCommunityTab)) {
      sessionStorage.removeItem('prevCommunityTab');
      router.push(`/community?tab=${prevCommunityTab}`);
      return;
    }

    if ('oldUser' in data) {
      handleOldUserLogin(data);
    } else {
      redirectUriItem.set(router.currentRoute.value.fullPath);
    }

    Broadcast.postMessage('login');
    basicStore.callbackAfterLoggedIn?.();
    basicStore.setLoginModalState(false);
    Object.assign(captcha, { url: '', identifyKey: '', value: '' });
  };

  const loginRequest = async () => {
    const { status, data, captchaUrl, identifykey } = await userAuthStore.login({
      ...accountData,
      ...(captcha.identifyKey && {
        captcha: {
          identifyKey: captcha.identifyKey,
          value: captcha.value,
        },
      }),
    });
    return { status, data, captchaUrl, identifykey };
  };

  if (!(await validateInputs())) {
    return;
  }

  try {
    Tracker['Click Login Detail Button'](Provider.EMAIL);
    const { status, data } = await loginRequest();
    if (status?.errorCode) {
      failCount.value = data.response?.failCount;
      throw new Error(status.errorMessage);
    }
    await handleLoginSuccess(data);
    if (!('token' in data)) {
      throw new Error('로그인에 실패하였습니다.');
    }

    if (data.isPasswordChangeRequired === true) {
      const confirm = await $customConfirm({
        message: '비밀번호 설정 후 6개월이 지났습니다.\n안전한 사용을 위해 비밀번호를 변경해주세요.',
        rightButtonLabel: '지금 변경하기',
        leftButtonLabel: '다음에 하기',
      });

      userAuthStore.renewPasswordDate(); // 확인, 취소 모두 비밀번호 변경일 갱신(promise 지만 await 필요 없는 로직)

      if (confirm === true) {
        router.push($getRoute().mypageInfo());
      }
    }
  } catch (error) {
    // 오류시 값 초기화
    if (captcha.value) {
      Object.assign(captcha, { ...captcha, value: '' });
    }
    accountData.userPwd = '';

    // 이메일 로그인시 비밀번호 5회 이상 오류시 캡챠 인증
    if (error?.response?.data?.status?.errorCode === 'ERROR_0002') {
      requestCaptchaImg();
      return;
    }

    // 캡챠 번호 또는 비밀번호 오류시
    if (error?.response?.data?.status?.errorCode === 'ERROR_0003') {
      requestCaptchaImg();
      return;
    }

    if (error) {
      await $alert(error?.response?.data?.status?.errorMessage ?? error?.message);
    }
  }
};

// 회원가입 페이지로 이동
const redirectToSignup = () => {
  redirectUriItem.set(router.currentRoute.value.fullPath);
  basicStore.setLoginModalState(false);
  Tracker['Click Signup Button']({
    location: 'popup_signup',
    url: window.location.href,
    path: route.path,
  });
  router.push({
    path: '/signup',
  });
};

// 캡챠 이미지 요청
const requestCaptchaImg = async () => {
  const [captchaUrl, identifyKey] = await userAuthStore.requestCaptchaImg();
  Object.assign(captcha, { ...captcha, identifyKey, url: URL.createObjectURL(captchaUrl) });
};

// 이전 사용자 처리 함수
const handleOldUserLogin = (data) => {
  userAuthStore.setOldInfo({
    oldId: accountData.email,
    oldEmail: data.oldUser.email ?? '',
    socialType: '',
    type: 'email',
    pw: accountData.userPwd,
  });
  if (data.oldUser.isEnableUserPhoneInput && typeof window !== 'undefined') {
    sessionStorage.setItem('useValid', 'input');
  }
  router.replace('/migration');
};

// 중복 계정 팝업 닫기 처리
const closeDuplicatedAccountModal = () => {
  StorageItem.local('phoneDuplication').remove();
  duplicatedAccounts.value = {
    createdAt: '',
    phoneNumber: '',
    accounts: [],
  };
};

watch(
  () => basicStore.isOpen,
  (val) => {
    if (!val) {
      accountData.email = '';
      accountData.userPwd = '';

      // 로그인 창 닫을시, 캡쳐값 초기화
      if (captcha.value) {
        Object.assign(captcha, { url: '', identifyKey: '', value: '' });
      }
      return;
    }

    const redirectUriItem = StorageItem.local(REDIRECT_URI);
    if (router.currentRoute.value.fullPath !== '/find') {
      redirectUriItem.set(router.currentRoute.value.fullPath);
      closeDuplicatedAccountModal();
    }
  },
);

// 최초 인입이후 라우트 변경에 대한 PageView
router.afterEach((to, from, failure) => {
  const userAuthStore = useUserAuthStore();
  // 뒤로 가기시..
  if (userAuthStore.user && basicStore.isOpen) {
    location.reload();
  }

  if (failure) {
    return;
  }

  const phoneDuplication = StorageItem.local('phoneDuplication').get();
  if (phoneDuplication) {
    duplicatedAccounts.value = JSON.parse(phoneDuplication);
    StorageItem.local('phoneDuplication').remove();
  }

  if (from.name === 'signup' && !userAuthStore.isAuthenticated) {
    const provider = StorageItem.local(PROVIDER_STATE_KEY_PREFIX).get() as Provider;
    if (!basicStore.isOpen && provider === Provider.EMAIL) {
      basicStore.setLoginModalState(true);
      redirectUriItem.remove();
    }
  }
});

onMounted(async () => {
  redirectUriItem = StorageItem.local(REDIRECT_URI);
});
</script>

<template>
  <!-- 회원 로그인 -->
  <Dialog v-model:visible="basicStore.isOpen" modal class="wb-dialog wb-dialog-login" :close-on-escape="false">
    <div class="wb-dialog-login__header">
      <div v-if="closeButtonVisible" class="wb-dialog-login__btn-group">
        <button class="wb-dialog-login__close-btn" @click="basicStore.setLoginModalState(false)">
          <span class="hide">닫기</span>
        </button>
      </div>
    </div>
    <div class="wb-dialog-login__container">
      <div class="wb-dialog-login__logo-container">
        <div class="wb-dialog-login__block wb-dialog-login__block--logo">
          <a href="/">월급쟁이부자들</a>
        </div>
        <!-- 캡챠 UI - 비밀번호 5번 틀린경우 노출됩니다.  -->
        <CaptchaLoginForm
          v-if="!!captcha.url"
          v-model:email="accountData.email"
          v-model:userPwd="accountData.userPwd"
          v-model:captchaValue="captcha.value"
          :captcha-url="captcha.url"
          @signin="handleSignin"
          @fetchCaptchaImg="requestCaptchaImg" />

        <!-- 기존 로그인 -->
        <LoginForm
          v-else
          v-model:email="accountData.email"
          v-model:userPwd="accountData.userPwd"
          @signin="handleSignin" />

        <!-- 로그인버튼 / 아이디,비밀번호 찾기 / 이메일 회원가입 버튼 -->
        <LoginFormBottomSection :captcha-url="captcha.url" @signin="handleSignin" @signup="redirectToSignup" />
      </div>
    </div>
  </Dialog>

  <!-- 소셜회원가입 중복 가입 계정 팝업 - 소셜회원가입 중 중복된 전화번호로 가입된 계정이 존재하는 경우, 아래 팝업이 노출됩니다. -->
  <DuplicatedSNSAccountDialog :duplicated-accounts="duplicatedAccounts" @closeModal="closeDuplicatedAccountModal" />
</template>

<style lang="scss" scoped></style>
