import { type Provider } from '~/policy';

import Analytics from '~/libs/Analytics';
import CriteoTag from '~/libs/CriteoTag';
import GoogleAds from '~/libs/GoogleAds';
import KakaoPixel from '~/libs/KakaoPixel';
import NaverPremium, { NaverPremiumTrackType } from '~/libs/NaverPremium';
import Pixel from '~/libs/Pixel';
import TagManager from '~/libs/TagManager';

import { type BoardContentDetailProps } from '~/pages/community/[id].vue';

import type { CommunityContentsGuideType } from '~/services/communityContentGuide/type';
import {
  type DesignModuleSlide,
  type DisplayProduct,
  type Product,
  type ProductForTracking,
  type ProductOption,
} from '~/services/main';
import { type MyPageStudyRoomClass, type MyPageStudyRoomLecture } from '~/services/mypage/type';
import { type PrePaymentOptions } from '~/services/payment';
import type { KollusParameter } from '~/services/player/type';
import { type SignupSourceType } from '~/services/userAuth/type';

export type ClickBannerPageType = 'CLASS' | 'COMMUNITY' | 'EVENT' | 'MYPAGE' | 'CURRICULUM';
export type ClickBannerType =
  | 'MAIN_TOP'
  | 'CLASS_BIG'
  | 'CLASS_TOP'
  | 'CLASS_MIDDLE'
  | 'CLASS_MID'
  | 'CLASS_SUB'
  | 'CLASS_PRODUCT_DETAIL'
  | 'CLASS_RECOMMEND_TEST'
  | 'LIVE_STUDY_AD'
  | 'COMMUNITY_CONTENTS'
  | 'COMMUNITY_SUB'
  | 'COMMUNITY_MAIN'
  | 'CLASS_BOTTOM'
  | 'PROMOTION'
  | 'STUDY'
  | 'HOME_ENTRY'
  | 'MYPAGE_SUB'
  | 'CURRICULUM_SUB'
  | 'RELATED_LECTURES'
  | 'COMMUNITY_SURVEY'
  | 'MYPAGE_SURVEY'
  | 'SEARCH_SURVEY'
  | 'COMMUNITY_DETAIL';
export type RoutePathEventType = {
  pageName: ClickBannerPageType;
  bannerType: ClickBannerType;
};

export type ClickMyClassroomMenuProps = {
  location?: 'hamburger' | 'mypage';
  url?: string;
  path?: string;
};

export type ClickSignupEventProps = {
  location?:
    | 'hamburger'
    | 'login'
    | 'info'
    | 'signup'
    | 'writing'
    | 'preview'
    | 'popup_signup'
    | 'add_wish_list'
    | 'follow'
    | 'writing'
    | 'like_comment'
    | 'follow_board'
    | 'writing'
    | 'my_activity'
    | 'comment' // 게시글 댓글 작성
    | 'like_content' // 게시글 좋아요
    | 'like_comment' // 댓글 좋아요
    | 'scrap_content'; // 게시글 스크랩 ='clip_content'

  pageName?: 'class' | 'community' | 'search' | 'event';
  url?: string;
  path?: string;
  categoryId?: string;
  displayId?: string;
  productId?: string;
  displayName?: string;
  boardId?: string;
  boardName?: string;
  boardContentId?: string;
  boardContentsId?: string;
  productName?: string;
  displayCategoryId?: string;
  productCategoryId?: string;
  boardContentsTitle?: string;
  boardContentsBestYn?: 'Y' | 'N';
  mustReadYn?: 'Y' | 'N';
  noticeYn?: 'Y' | 'N';
  bestYn?: 'Y' | 'N';
  buttonName?: string;
  popupType?: string;
  displaySeq?: number | null;
  videoTitle?: string;
  productLecSeq?: number;
  gnbId?: string;
  gnbName?: string;
};

export type ClickLoginEventProps = ClickSignupEventProps & {
  text?:
    | '로그인'
    | '로그인하고 쿠폰 적용가를 확인하세요!'
    | '지금 가입하고 4만원 쿠폰 받기'
    | '결제하기'
    | '오픈 알림 신청하기'
    | '글쓰기'
    | '맛보기강의'
    | '댓글작성'
    | '게시글 조회'
    | '팔로우'
    | '게시글 좋아요'
    | '게시글 스크랩'
    | '댓글 좋아요'
    | '내가쓴글'
    | '구해줘월부'
    | '월부레터'
    | '팔로우'
    | '미리보기';
};

export type ClickBestReviewEventProps = {
  type: 'unfold' | 'fold' | 'all';
  text: '더보기' | '닫기' | '전체 후기 보기';
  url: string;
  path: string;
  pageName: 'class' | 'community';
  categoryId: string;
  displayId: string;
  productId: string;
  displayName: string;
};

export type ClickReportRoute = {
  url: string;
  path: string;
};

/** 로깅에 꼭 필요한 값들 아니면 optional */
export type CommunityContentProps = {
  boardId: string;
  boardName: string;
  boardTypeCd: string;
  boardTypeName?: string;
  boardContentsId: string;
  boardContentsTitle: string;
  boardContentsBestYn?: 'Y' | 'N';
  mustReadYn?: 'Y' | 'N';
  followingYn?: 'Y' | 'N';
  viewCount: string;
  commentCount: string;
  likeCount: string;
  /** 작성자 ID */
  regId: string;
};

export type ViewCommunityContentProps = CommunityContentProps & {
  referrer_path: string;
  referrer_url: string;
  calledFrom?: string;
  calledFromType?: string;
};

export type DownloadCommunityFileProps = ViewCommunityContentProps & {
  calledFrom: string;
  calledFromType: string;
  calledUrl: string;
};

/** 로깅에 꼭 필요한 값들 아니면 optional */
export type ClickCommunityContentProps = CommunityContentProps & {
  pageName: 'CLASS' | 'COMMUNITY' | 'SEARCH';
  location: string;
  relatedTitle?: '작성자의 다른글' | '최근 전문가 칼럼' | string | null;
  relatedContentsId?: string;
  path: string;
  url: string;
  /** CategoryId 와 같음 */
  gnbId?: string;
  /** CategoryName 과 같음 */
  gnbName?: string;
};

export type ViewLocationProps = {
  pageName: 'CLASS';
  location: 'CURATION_CLASS' | 'CURATION_COMMUNITY' | 'CURATION_VIDEO';
  curationId: string;
  curationTitle: string;
  curationOrder: number;
  displayId?: string;
  displayName?: string;
};

export type ViewClassListProps = {
  userEmail: string;
  cateSeq: string;
  products: Product[] | DisplayProduct[];
};

export type ViewSearchListProps = {
  userEmail: string;
  searchKeyword: string;
  products: Product[] | DisplayProduct[];
};

export type ViewProductProps = {
  userEmail: string;
  displayId: string;
  displayName: string;
  productId: string;
  productName: string;
  displayCategoryId: string;
  earlyBirdYn: string;
  previewYn: string;
  originalYn: string;
  stayYn: string;
  alarmYn: string;
  value: string | number;
  cateSeq: string;
  referrer_url: string;
  referrer_path: string;
  inviteCode?: 'Y' | 'N';
};

export type CompletePurchaseProps = {
  isMobile: boolean;
  pgValue: string;
  pgName: string;
  userEmail: string;
  id: number;
  value: number;
  name: string;
  category: string;
  displayCategoryId: string;
  paymentSeq: number;
  product: Product | DisplayProduct;
};

export type PixelEventProps = {
  displayId: string;
  displayName: string;
  value: string | number;
};

export type ClickBoardItemProps = {
  pageName?: string;
  location?: string;
  gnbType?: string;
  gnbId?: string;
  gnbName?: string;
  boardId?: string | null;
  boardName?: string | null;
  url?: string;
  path?: string;
  menuType?: 'GNB' | 'bottom';
};

interface ProductForPurchase extends Product {
  payParam: PrePaymentOptions;
  selectedPg: {
    pgValue: string;
    pgName: string;
  };
}

interface WBTestEventProperties {
  testName: string;
  [x: string]: string;
}

/**
 * 이벤트 네이밍은 Segment가 제안하는 Proper Case와 개발자 입장에서 편한 동사 + 목적어로 혼합
 * Analytics로 쏘는 모든 이벤트가 선언되있는 콜렉션
 * @see https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
 */
class Tracker {
  /**
   * "내 강의실" 클릭 시
   */
  ['Click My Classroom Menu'](params: ClickMyClassroomMenuProps) {
    console.info('Click My Classroom Menu', params);
    return Analytics.track('Click My Classroom Menu', params);
  }

  /**
   * 유저가 회원가입 페이지를 조회할 때
   */
  ['View Signup'](type: 'email' | 'social') {
    console.info('EV: View Signup', { type });
    return Analytics.track('View Signup', { type });
  }

  /**
   * 유저가 회원가입 버튼을 클릭할 때
   */
  ['Click Signup Button'](params: ClickSignupEventProps) {
    console.info('EV: Click Signup Button', params);
    return Analytics.track('Click Signup Button', { ...params });
  }

  /**
   * 유저가 회원가입을 완료할 때
   */
  ['Complete Signup'](oauth: 'email' | 'kakao' | 'naver' | 'google', signupSource: SignupSourceType = 'ETC') {
    console.info('EV: Complete Signup', { oauth, signupSource });
    Pixel.completeRegistration();
    KakaoPixel.completeRegistration();
    // GTM
    TagManager.completeRegistration();
    // Naver
    NaverPremium.track(NaverPremiumTrackType.SIGN_UP, '0');

    // Google Ads
    GoogleAds.signupConversion();
    return Analytics.track('Complete Signup', { oauth, signupSource });
  }

  /**
   * 유저가 로그인 페이지를 조회할 때
   */
  ['View Login']() {
    console.info('EV: View Login');
    return Analytics.track('View Login');
  }

  /**
   * 팝업 조회
   */
  ['View Popup'](payload: { [x: string]: unknown }) {
    console.info('EV: View Popup', payload);
    return Analytics.track('View Popup', { ...payload });
  }

  /**
   * 팝업 클릭
   */
  ['Click Popup'](payload: { [x: string]: unknown }) {
    console.info('EV: Click Popup');
    return Analytics.track('Click Popup', { ...payload });
  }

  /**
   * 유저가 메인 페이지 영역을 조회할 때
   */
  ['View Location'](payload: ViewLocationProps) {
    console.info('EV: View Location', payload);
    return Analytics.track('View Location', { ...payload });
  }

  /**
   * 유저가 로그인 버튼을 클릭할 때
   */
  ['Click Login Button'](params: ClickLoginEventProps) {
    console.info('EV: Click Login Button', params);
    return Analytics.track('Click Login Button', { ...params });
  }

  /**
   * 유저 로그인시, Provider 값 기록
   */
  ['Click Login Detail Button'](provider: Provider) {
    console.info('EV: Click Login Detail Button', provider);
    return Analytics.track('Click Login Detail Button', { provider });
  }

  /**
   * 유저가 로그인을 완료할 때
   */
  ['Complete Login'](oauth: 'email' | Provider) {
    console.info('EV: Complete Login', { oauth });
    return Analytics.track('Complete Login', { oauth });
  }

  /**
   * 유저가 상단 로고버튼부터 GNB 클릭할 때
   */
  ['Click Navigation'](params: ClickBoardItemProps) {
    console.info('EV: Click Navigation', { params });
    return Analytics.track('Click Navigation', { ...params });
  }

  /**
   * 커뮤니티 메인의 더보기 버튼 클릭시
   */
  ['Click More Button'](params: ClickBoardItemProps) {
    console.info('EV: Click More Button', { params });
    return Analytics.track('Click More Button', { ...params });
  }

  /**
   * 유저가 우측 하단 채널톡 플로팅 아이콘 클릭할 때
   */
  ['Click Channeltalk Floating']() {
    return Analytics.track('Click Channeltalk Floating');
  }

  /**
   * 유저가 검색 키워드 입력 후 실행할 때
   */
  ['Click Search Keyword'](keyword: string) {
    return Analytics.track('Click Search Keyword', { keyword });
  }

  /**
   * 유저가 홈 최상단의 이벤트 배너를 클릭할 때
   */
  ['Click Main Top Banner'](bannerName: string = '') {
    return Analytics.track('Click Main Top Banner', { bannerName });
  }

  /**
   * 유저가 홈 상단의 메인 배너를 클릭할 때
   */
  ['Click Main Big Banner'](slide: DesignModuleSlide) {
    return Analytics.track('Click Main Big Banner', { ...slide });
  }

  /**
   * 유저가 홈의 퀵메뉴를 클릭할 때
   */
  ['Click Main Quick Menu'](name: string) {
    return Analytics.track('Click Main Quick Menu', { name });
  }

  /**
   * 유저가 홈에 진열된 상품을 클릭할 때
   */
  ['Click Main Display Product'](payload: ProductForTracking) {
    return Analytics.track('Click Main Display Product', { ...payload });
  }

  /**
   * 유저가 홈의 중간 영역에 위치한 배너를 클릭할 때
   */
  ['Click Main Mid Banner'](slide: DesignModuleSlide) {
    return Analytics.track('Click Main Mid Banner', { ...slide });
  }

  /**
   * 홈
   */
  ['View Main Home'](payload: ViewCommunityContentProps) {
    return Analytics.track('View Main Home', { ...payload });
  }

  /**
   * 유저가 상품상세페이지를 조회할 때
   */
  ['View Product'](payload: ViewProductProps) {
    Pixel.viewContent({ displayId: payload.displayId, displayName: payload.displayName, value: payload.value });

    CriteoTag.addViewItemTag({
      email: payload.userEmail,
      productId: payload.displayId,
    });

    return Analytics.track('View Product', { ...payload, displaycategoryId: payload.cateSeq });
  }

  /**
   * 유저가 상품 상세페이지 내 탭을 클릭할 때
   */
  ['Click Product Tab'](tabName: string) {
    return Analytics.track('Click Product Tab', { tabName });
  }

  /**
   * 유저가 상품상세페이지 내 결제하기 버튼을 클릭했을 때
   */
  ['Click Product Purchase Button'](option: ProductOption | {}) {
    return Analytics.track('Click Product Purchase Button', { ...option });
  }

  /**
   * 유저가 마감 된 상품의 상세페이지 내 대기신청 버튼을 클릭할 때
   */
  ['Click Product Wait Button'](option: ProductOption | {}) {
    return Analytics.track('Click Product Wait Button', { ...option });
  }

  /**
   * 유저가 판매 예정 상품의 상세페이지 내 오픈알림 신청하기 버튼을 클릭할 때
   */
  ['Click Product Open Alarm Button'](displayId?: string) {
    KakaoPixel.preparation({ tag: 'RECEIVE_NOTIFICATION' });
    console.info('EV: Click Product Open Alarm Button', { displayId });
    return Analytics.track('Click Product Open Alarm Button', { displayId });
  }

  /**
   * 유저가 주문결제 페이지 하단 결제하기 버튼을 클릭할 때
   */
  ['Click Order Purchase Button'](payload: ProductForPurchase) {
    console.info('EV: Click Order Purchase Button', payload);
    return Analytics.track('Click Order Purchase Button', payload);
  }

  /**
   * 유저가 결제를 성공적으로 완료했을때
   */
  ['Complete Purchase'](payload: CompletePurchaseProps) {
    Pixel.purchase({
      id: payload.id,
      name: payload.name,
      value: payload.value,
      category: '',
    });

    KakaoPixel.purchase({
      products: [
        {
          id: payload.product.displaySeq,
          name: payload.product.displayName,
          quantity: 1,
          price: payload.product.price,
        },
      ],
      currency: 'KRW',
      total_quantity: '1',
      total_price: payload.value,
    });

    NaverPremium.track(NaverPremiumTrackType.FINISHED_PAYMENT, `${payload.value}`);

    CriteoTag.addTrackTransaction({
      email: payload.userEmail ?? '',
      transactionId: String(payload.paymentSeq ?? ''),
      items: [
        {
          id: String(payload.id ?? ''),
          price: payload.value,
          quantity: 1,
        },
      ],
    });

    // GTM
    TagManager.purchase({
      orderId: payload.paymentSeq,
      resultOrderPrice: payload.value,
    });

    //Naver
    NaverPremium.purchase({ resultOrderPrice: payload.value });

    // Google Ads
    GoogleAds.purchaseConversion({
      value: payload.value,
      currency: 'KRW',
    });
    const { product, ...restPayload } = payload;
    return Analytics.track('Complete Purchase', { ...product, ...restPayload });
  }

  /**
   * 유저가 상품을 찜했을때
   */
  ['Add Wish List'](payload: Product) {
    Pixel.addToWishlist();
    return Analytics.track('Add Wish List', { ...payload });
  }

  /**
   * 유저가 회원가입을 완료하고 나서 신규회원 혜택을 조회할 때
   */
  ['View Newmember Benefit']() {
    return Analytics.track('View Newmember Benefit');
  }

  /**
   * 유저가 회원가입을 완료하고 나서 신규회원 혜택을 클릭할 때
   */
  ['Click Newmember Benefit'](option: 'usebenefit' | 'home') {
    return Analytics.track('Click Newmember Benefit', {
      option,
    });
  }

  /**
   * 유저가 상반기 결산 프로모션 조회
   */
  ['View Summer Discount Event']() {
    return Analytics.track('View Summer Discount Event');
  }

  /**
   * 유저가 상반기 결산 프로모션의 50% 쿠폰 회원가입 CTA를 클릭시
   */
  ['Click Signup Button In Summer 70']() {
    return Analytics.track('Click Signup Button In Summer 70');
  }

  /**
   * 유저가 토픽 탭 메뉴를 클릭할 때
   */
  ['Click Community Menu'](topic: string) {
    return Analytics.track('Click Community Menu', {
      topic,
    });
  }

  /**
   * 커뮤니티 중간 배너 클릭
   */
  ['Click Community Middle Banner']() {
    return Analytics.track('Click Community Middle Banner');
  }

  /**
   * 커뮤니티 게시글 링크 클릭
   */
  ['Click Community Content'](payload: ClickCommunityContentProps) {
    console.info('EV: Click Community Content', { ...payload });
    return Analytics.track('Click Community Content', { ...payload });
  }

  /**
   * 커뮤니티 상세 게시글 조회
   */
  ['View Community Content'](payload: ViewCommunityContentProps) {
    console.info('EV: View Community Content', { ...payload });
    return Analytics.track('View Community Content', {
      ...payload,
    });
  }

  /**
   * 커뮤니티 글쓰기 페이지 조회
   */
  ['View Community Writing Page']() {
    return Analytics.track('View Community Writing Page');
  }

  /**
   * 커뮤니티 글쓰기 완료
   */
  ['Save Community Content'](payload: Record<string, any>) {
    return Analytics.track('Save Community Content', {
      ...payload,
    });
  }

  /**
   * 커뮤니티 리스트 무한 스크롤 다음 페이지 호출시
   */
  ['Scroll Community List Next Page'](topicSeq: number, page: number) {
    return Analytics.track('Scroll Community List Next Page', {
      topicSeq,
      page,
    });
  }

  /**
   * 커뮤니티 상세 게시글 스크랩 클릭시
   */
  ['Clip Community Content'](
    payload: Partial<BoardContentDetailProps> & {
      boardId: string;
      boardTypeName: string;
      boardContentsId: string;
      mustReadYn: 'Y' | 'N';
      isScraped: boolean;
      location: 'comment' | 'floating' | 'header'; //=> 글 하단의 Textarea | PC 플로팅 영역 | 모바일 헤더
    },
  ) {
    return Analytics.track('Clip Community Content', {
      ...payload,
    });
  }

  /**
   * 커뮤니티 상세 게시글 좋아요 클릭시
   */
  ['Like Community Content'](
    payload: Partial<BoardContentDetailProps> & {
      boardId: string;
      boardTypeName: string;
      boardContentsId: string;
      mustReadYn: 'Y' | 'N';
      isLiked: boolean;
      location: 'comment' | 'floating' | 'header'; //=> 글 하단의 Textarea | PC 플로팅 영역 | 모바일 헤더
    },
  ) {
    return Analytics.track('Like Community Content', {
      ...payload,
    });
  }
  /**
   * 커뮤니티 상세 게시글 공유시
   */
  ['Share Community Content'](
    payload: Partial<{
      location: 'comment' | 'floating' | 'header'; //=> 글 하단의 Textarea | PC 플로팅 영역 | 모바일 헤더
      [key: string]: any;
    }>,
  ) {
    console.info('EV: Share Community Content', payload);
    return Analytics.track('Share Community Content', { ...payload });
  }

  /**
   * 커뮤니티 유저 프로필 조회 시
   */
  ['View Community User Profile'](payload: any) {
    return Analytics.track('View Community User Profile', {
      ...payload,
    });
  }

  /**
   * 커뮤니티 콘텐츠 댓글 작성 시
   */
  ['Complete Write Community Comment'](payload: any) {
    return Analytics.track('Complete Write Community Comment', {
      ...payload,
    });
  }

  /**
   * 마이페이지에서 내 강의실 진입
   */
  ['View My Classroom Menu'](payload?: { referrer_url: String; referrer_path: String }) {
    console.log('payload :>> ', payload);
    return Analytics.track('View My Classroom Menu', payload);
  }

  /**
   * 마이페이지에서 내 강의실에서 구매한 강의 클릭
   */
  ['Click Purchased Product'](payload: MyPageStudyRoomClass) {
    return Analytics.track('Click Purchased Product', {
      ...payload,
    });
  }

  /**
   * 마이페이지에서 구매한 강의 진입 후 특정 강의 클립 클릭 (콜러스 재생 호출)
   */
  ['Click Class Video Clip'](payload: MyPageStudyRoomLecture & KollusParameter) {
    return Analytics.track('Click Class Video Clip', {
      ...payload,
    });
  }

  /**
   * 마이페이지에서 목실감 클릭
   */
  ['Click Moksilgam Button']() {
    return Analytics.track('Click Moksilgam Button');
  }

  /**
   * 마이페이지 > 친구초대 페이지에서 초대코드 복사하기 버튼 클릭
   */
  ['Copy Invitation Code']() {
    return Analytics.track('Copy Invitation Code');
  }

  /**
   * 각 카테고리별 상품 목록 진입시
   */
  ['View Class List'](payload: ViewClassListProps) {
    CriteoTag.addViewListTag({
      email: payload.userEmail,
      categoryId: payload.cateSeq,
      searchKeyword: '',
      items: payload.products.slice(0, 3).map((product) => String(product.displaySeq ?? '')),
    });

    return Analytics.track('View Class List', {
      cateSeq: payload.cateSeq,
    });
  }

  /**
   * 각 카테고리별 상품 목록 진입시
   */
  ['View Search List'](payload: ViewSearchListProps) {
    CriteoTag.addViewListTag({
      email: payload.userEmail,
      categoryId: '',
      searchKeyword: payload.searchKeyword,
      items: payload.products.slice(0, 3).map((product) => String(product.displaySeq ?? '')),
    });
  }

  /**
   * 햄버거 메뉴 클릭시
   */
  ['Click Hamburger Button']() {
    console.info('EV: Click Hamburger Button');
    return Analytics.track('Click Hamburger Button');
  }

  /**
   * 상품상세 -> 베스트리뷰 -> 전체후기 보기/더보기 클릭시
   */
  ['Click Best Review'](payload: ClickBestReviewEventProps) {
    console.info('EV: Click Best Review', { ...payload });
    return Analytics.track('Click Best Review', { ...payload });
  }

  /**
   * 햄버거 메뉴 > 카테고리 클릭시
   */
  ['Click Hamburger Category'](params: Partial<{ tabName: '클래스' | '커뮤니티'; categoryId?: string; text: string }>) {
    const { tabName, categoryId, text } = params;

    console.info('EV: Click Hamburger Category', tabName, categoryId, text);
    return Analytics.track('Click Hamburger Category', {
      tabName,
      categoryId,
      text,
    });
  }

  /**
   * 내강의실 > 클래스 > 과제하기 버튼 클릭시
   */
  ['Click Report Button'](
    tabName: '클래스' | '과제관리',
    buttonName: '수강생들 과제보기' | '과제보기' | '과제하기',
    route: ClickReportRoute,
  ) {
    console.info('EV: Click Report Button', tabName);
    return Analytics.track('Click Report Button', {
      tabName,
      buttonName,
      ...route,
    });
  }

  /**
   * 배너 클릭 시
   */
  ['Click Banner'](
    pageName: ClickBannerPageType,
    bannerType: ClickBannerType,
    bannerUrl: string,
    options?: {
      bannerUrl?: string;
      bannerOrder?: Number;
      url?: string;
      path?: string;
      designSlideId?: string;
      designId?: string;
      slideTitle?: string;
      slideSubTitle?: string;
      slideOrder?: number;
      buttonTitle?: string;
      boardName?: string;
      boardContentsId?: string;
      boardContentsTitle?: string;
      boardContentsBestYn?: 'Y' | 'N';
      mustReadYn?: 'Y' | 'N';
      gnbId?: number;
      gnbName?: string;
      boardId?: string;
      displaySeq?: string;
    },
  ) {
    console.info('EV: Click Banner', pageName, bannerType, bannerUrl, options);
    return Analytics.track('Click Banner', {
      pageName,
      bannerType,
      bannerUrl,
      ...options,
    });
  }

  /**
   * 커뮤니티 상세 게시글 조회
   */
  ['View Community List'](
    boardName: String,
    options: {
      referrer_url: String;
      referrer_path: String;
    },
  ) {
    console.info('EV: View Community List', {
      boardName,
      ...options,
    });

    return Analytics.track('View Community List', {
      boardName,
      ...options,
    });
  }

  /**
   * 커뮤니티 > 유저 프로필 > 팔로우 클릭 시
   */
  ['Follow Community User'](
    location:
      | 'COMMUNITY_CONTENT'
      | 'USER_PROFILE'
      | 'REMARKABLE_MEMBER'
      | 'COMMENT_RANKING'
      | 'GROUP_MEMBER'
      | 'SEARCH_RESULTS',
    options: {
      followedUserId?: Number;
      type: 'FOLLOW' | 'UNFOLLOW';
      url: String;
      path: String;
    },
  ) {
    console.info('EV: Follow Community User', { location, ...options });

    return Analytics.track('Follow Community User', {
      location,
      ...options,
    });
  }

  /**
   * 사용자 아바타를 클릭하여 프로필 페이지로 이동시
   * location
   * - COMMUNITY_CONTENT: 게시글 상세 아바타 (프로필)
   * - COMMENT_RANKING: 댓글 랭킹 아바타
   * - REMARKABLE_MEMBER: 주목받는멤버 아바타
   * - COMMUNITY_LIST: 게시글 목록 아바타
   * - COMMUNITY_COMMENT: 댓글 아바타
   */
  ['Click User Profile'](
    location:
      | 'COMMUNITY_CONTENT'
      | 'COMMENT_RANKING'
      | 'REMARKABLE_MEMBER'
      | 'COMMUNITY_LIST'
      | 'COMMUNITY_COMMENT'
      | 'GROUP_MEMBER'
      | 'SEARCH_RESULTS',
    options?: Partial<{
      userId?: number | string;
      url: string;
      path: string;
    }>,
  ) {
    console.info('EV: Click User Profile', { location, ...options });
    return Analytics.track('Click User Profile', {
      location,
      ...options,
    });
  }

  // promotionName - 이벤트명 [discount, introduce-friend]
  // calledfrom - 외부에서 월부닷컴에 진입했을때
  // calledFromType - 외부에서 월부닷컴에 진입했을때
  ['View Promotion'](
    promotionName: string,
    options?: {
      referrer_url?: string;
      referrer_path?: string;
      calledFrom?: string;
      calledFromType?: string;
    },
  ) {
    console.info('EV: View Promotion', { promotionName, ...options });
    return Analytics.track('View Promotion', {
      promotionName,
      ...options,
    });
  }

  ['View Button'](buttonType: string) {
    console.info('EV: View Button', { buttonType });
    return Analytics.track('View Button', {
      buttonType,
    });
  }

  ['Click Button'](
    buttonType: string,
    buttonName: string,
    options: { userId?: number | string; displaySeq?: string; displayName?: string; [key: string]: any } = {},
  ) {
    console.info('EV: Click Button', buttonType, buttonName, options);
    return Analytics.track('Click Button', {
      buttonType,
      buttonName,
      ...options,
    });
  }

  ['View Bottom Sheet'](options: { bottomSheetType: string; buttonType?: string; buttonName?: string }) {
    console.info('EV: View Bottom Sheet', options);
    return Analytics.track('View Bottom Sheet', {
      ...options,
    });
  }

  ['Click Bottom Sheet'](options: {
    bottomSheetType?: string;
    buttonType?: string;
    buttonName?: string;
    popupType?: string;
    displaySeq?: string;
  }) {
    console.info('EV: Click Bottom Sheet', options);
    return Analytics.track('Click Bottom Sheet', options);
  }

  ['Click Notification'](options: { notificationType?: any }) {
    console.info('EV: Click Notification', options);
    return Analytics.track('Click Notification', options);
  }

  ['View Tally Survey'](options: { pageNumber: number }) {
    console.info('EV: View Tally Survey', options);
    return Analytics.track('View Tally Survey', options);
  }

  ['Complete Tally Survey'](options: { formId: string; userId: number | undefined; question: string; answer: string }) {
    console.info('EV: Complete Tally Survey', options);
    return Analytics.track('Complete Tally Survey', options);
  }

  ['Scroll Position'](options: { position: number }) {
    console.info('EV: Scroll Position', options);
    return Analytics.scroll(options);
  }

  ['Click Notification Center'](options: { notificationType: string }) {
    console.info('EV: Click Notification Center', options);
    return Analytics.track('Click Notification Center', options);
  }

  // 상세페이지 공유 > 카카오 / URL 복사 버튼 클릭 시
  ['Click Share Button'](options: { type: 'kakao' | 'clipboard'; url: string; path: string; [x: string]: string }) {
    console.info('EV: Click Share Button', options);
    return Analytics.track('Click Share Button', options);
  }

  // 상세페이지 리뷰 Sorting(최신순, 추천순) 클릭 시
  ['View Best Review Detail'](options: {
    order: number;
    reviewContent: string;
    displaySeq: number;
    productSeq: number;
  }) {
    console.info('EV: View Best Review Detail', options);
    return Analytics.track('View Best Review Detail', options);
  }

  // 상세페이지 리뷰 Sorting(최신순, 추천순) 클릭 시
  ['View Profit Review Detail'](options: {
    order: number;
    displaySeq: number;
    productSeq: number;
    nickname: string;
    description: string;
    thumbnail: string;
    image: string;
  }) {
    console.info('EV: View Profit Review Detail', options);
    return Analytics.track('View Profit Review Detail', options);
  }

  // 상세페이지 리뷰 Sorting(최신순, 추천순) 클릭 시
  ['Click All Reviews'](options: { displaySeq: number }) {
    console.info('EV: Click All Reviews', options);
    return Analytics.track('Click All Reviews', options);
  }

  // 상세페이지 리뷰 Sorting(최신순, 추천순) 클릭 시
  ['Click Review Sorting'](options: { type: 'recommended' | 'latest' }) {
    console.info('EV: Click Review Sorting', options);
    return Analytics.track('Click Review Sorting', options);
  }

  // 상세페이지 리뷰 Sorting(최신순, 추천순) 클릭 시
  ['Click Review Detail'](options: {
    reviewType: 'best' | 'general' | 'history' | 'product';
    reviewContent?: string;
    displaySeq?: number;
    productSeq?: number;
  }) {
    console.info('EV: Click Review Detail', options);
    return Analytics.track('Click Review Detail', options);
  }

  // 상세페이지 리뷰 Sorting(최신순, 추천순) 클릭 시
  ['Click Pagination'](options: { type: 'review'; pageNumber: number }) {
    console.info('EV: Click Pagination', options);
    return Analytics.track('Click Pagination', options);
  }

  // 상세페이지 리뷰 Sorting(최신순, 추천순) 클릭 시
  ['Click Like Review'](options: {
    reviewType: 'best' | 'general';
    reviewContent: string;
    displaySeq: number;
    productSeq: number;
    count: number;
    likeYn: 'Y' | 'N';
  }) {
    console.info('EV: Click Like Review', options);
    return Analytics.track('Click Like Review', options);
  }

  // 크리에이터 공유 보상
  ['Click Creator Share Button'](options: { type: 'kakao' | 'clipboard'; url: string; path: string }) {
    console.info('EV: Click Creator Share Button', options);
    return Analytics.track('Click Creator Share Button', options);
  }

  ['Click File Download'](options: DownloadCommunityFileProps) {
    console.info('EV: Click File Download', options);
    return Analytics.track('Click File Download', options);
  }

  ['Click Preview Video'](options: { displaySeq?: number | null; videoTitle: string; productLecSeq: number }) {
    console.info('EV: Click Preview Video', options);
    return Analytics.track('Click Preview Video', options);
  }

  /** 월부 테스트 조회 */
  ['View Test'](options: WBTestEventProperties) {
    console.info('EV: View Test', options);
    return Analytics.track('View Test', options);
  }

  /** 월부 테스트 [시작하기] 버튼 클릭 */
  ['Click Start Test Button'](options: WBTestEventProperties) {
    console.info('EV: Click Start Test Button', options);
    return Analytics.track('Click Start Test Button', options);
  }

  /** 월부 테스트, 테스트 완료 */
  ['Complete Test'](options: WBTestEventProperties) {
    console.info('EV: Complete Test', options);
    return Analytics.track('Complete Test', options);
  }

  /** 월부 테스트, 결과 조회 */
  ['View Report'](options: WBTestEventProperties) {
    console.info('EV: View Report', options);
    return Analytics.track('View Report', options);
  }

  /** 월부 테스트, 추천하기 클릭 */
  ['Click Test Recommend Content'](options: WBTestEventProperties) {
    console.info('EV: Click Test Recommend Content', options);
    return Analytics.track('Click Test Recommend Content', options);
  }

  /** 상페 수강후기 클릭시 */
  ['Click Review Summary'](options: { displaySeq: number }) {
    console.info('EV: Click Review Summary', options);
    return Analytics.track('Click Review Summary', options);
  }
  /**
   * 상품 상세 페이지 공유시
   */
  ['Share Product Content'](payload: Partial<{ displaySeq: string | number }>) {
    console.info('EV: Share Product Content', payload);
    return Analytics.track('Share Product Content', payload);
  }

  /** (아하모먼트) 월부커 게시글 가이드 열림 */
  ['View Community Content Guide'](payload: { contentId: number; guideType: CommunityContentsGuideType }) {
    console.info('EV: View Community Content Guide', payload);
    return Analytics.track('View Community Content Guide', payload);
  }

  /** (아하모먼트) 월부커 게시글 가이드 내 액션 클릭 */
  ['Click Community Content Guide'](payload: { contentId: number; guideType: CommunityContentsGuideType }) {
    console.info('EV: Click Community Content Guide', payload);
    return Analytics.track('Click Community Content Guide', payload);
  }

  /** PWA V2 모듈 열림 */
  ['View PWA Install Guide Popup']() {
    console.info('EV: View PWA Install Guide Popup');
    return Analytics.track('View PWA Install Guide Popup');
  }

  /** PWA V2 모듈 내 버튼 클릭 */
  ['Click PWA Install Guide Popup'](payload: { button: 'KAKAO' | 'INSTALL' | 'CLOSE' | 'COPY' }) {
    console.info('EV: Click PWA Install Guide Popup', payload);
    return Analytics.track('Click PWA Install Guide Popup', payload);
  }
  /**
   * 커뮤니티 홈/게시판 검색 트리거
   */
  ['Click Community Search Keyword'](
    payload: Partial<{
      boardSeq: string | number;
      boardName: string;
      searchType: string;
      keyword: string;
      sortOrder: string;
      viewType: string | number;
      page: string | number;
    }>,
  ) {
    console.info('EV: Click Community Search Keyword', payload);
    return Analytics.track('Click Community Search Keyword', payload);
  }
  /**
   * 커뮤니티 전용 검색 드롭다운 변경
   */
  ['Change Dropdown'](payload: Partial<{ sortOrder: string; viewType: string | number }>) {
    console.info('EV: Change Dropdown', payload);
    return Analytics.track('Change Dropdown', payload);
  }
  /**
   * 커뮤니티 전용 검색 뷰 이벤트
   */
  ['View Search Results'](
    payload: Partial<{
      boardSeq: string | number;
      boardName: string;
      searchType: string;
      keyword: string;
      sortOrder: string;
      viewType: string | number;
      page: number;
    }>,
  ) {
    console.info('EV: View Search Results', payload);
    return Analytics.track('View Search Results', payload);
  }

  /**
   * 커뮤니티 상세 > 댓글 아이콘 클릭
   */
  ['Click Comment Button'](
    payload: Partial<{
      boardContentsSeq: string | number;
      boardSeq: string | number;
      boardName: string;
      pathname: string;
      referrerUrl: string;
      location: 'floating' | 'footer'; //=> PC 플로팅 영역 | 모바일 footer
    }>,
  ) {
    console.info('EV: Click Comment Button', payload);
    return Analytics.track('Click Comment Button', payload);
  }

  /**
   * 커뮤니티 상세 > 댓글 영역 노출시
   * 커뮤니티 상세 > 댓글 영역 > 전체보기 버튼선택
   * 커뮤니티 상세 > 댓글영역 > 페이지네이션  변경
   */
  ['View Community Comment'](
    payload: Partial<{
      boardContentsSeq: string | number;
      boardSeq: string | number;
      boardName: string;
      page: number;
      type: '영역노출' | '전체보기' | '페이지변경';
    }>,
  ) {
    console.info('EV: View Community Comment', payload);
    return Analytics.track('View Community Comment', payload);
  }

  /**
   * @summary 추석프로모션
   * @todo 24.09 추석 이후 제거
   */
  ['Chuseok:View Section'](payload: { section: string }) {
    console.info('EV: Chuseok:View Section', payload);
    return Analytics.track('Chuseok: View Section', payload);
  }

  /**
   * @summary 추석프로모션
   * @todo 24.09 추석 이후 제거
   */
  ['Chuseok:Click Product Link'](payload: { displayType: 'package' | 'product'; [key: string]: string }) {
    console.info('EV: Chuseok:Click Product Link', payload);
    return Analytics.track('Chuseok: Click Product Link', payload);
  }

  /**
   * @summary 유투브 숏폼 관련 이벤트 추가
   * @todo 성과에 따라 향후 제거될 수 있음
   */
  ['Click Shorts Content'](payload: { videoId: string }) {
    console.info('Click Shorts Content', payload);
    return Analytics.track('Click Shorts Content', payload);
  }
  /**
   * @summary 유투브 숏폼 관련 이벤트 추가
   * @todo 성과에 따라 향후 제거될 수 있음
   */
  ['View Shorts Content'](payload: {}) {
    console.info('View Shorts Content', payload);
    return Analytics.track('View Shorts Content', payload);
  }
}

export default new Tracker();
